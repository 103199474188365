import { PayloadAction } from '@reduxjs/toolkit';
import { analyticsTrackEvent, AnalyticsEvent } from 'fwi-fe-utils';

const category = 'Navigation';

// Event Names
const DASHBOARD_SELECTED = 'Dashboard Selected';

// Labels
const FROM_ACCOUNT_DROPDOWN = 'From Account Dropdown';
const FROM_LOGO = 'From Logo';
const FROM_DASHBOARD = 'From Dashboard';
const FROM_BENTO = 'From Bento';

/**
 * The analytics event to trigger when the user changes their company.
 */
export const accountChanged = analyticsTrackEvent.bind(null, {
  eventName: 'Account Changed',
  properties: { category },
});

/**
 * The analytics event to trigger when the user manually logs out via the
 * account dropdown.
 */
export const loggedOutFromDropdown = analyticsTrackEvent.bind(null, {
  eventName: 'Logged Out',
  properties: {
    category,
    label: FROM_ACCOUNT_DROPDOWN,
  },
});

/**
 * The analytics event to trigger when the user navigates to the dashboard by
 * clicking on the FWI logo at the top left of the page.
 */
export const dashboardSelectedFromLogo = analyticsTrackEvent.bind(null, {
  eventName: DASHBOARD_SELECTED,
  properties: {
    category,
    label: FROM_LOGO,
  },
});

/**
 * The analytics event to trigger when the user navigates to the dashboard by
 * clicking the dashboard menu item from the account dropdown.
 */
export const dashboardSelectedFromDropdown = analyticsTrackEvent.bind(null, {
  eventName: DASHBOARD_SELECTED,
  properties: {
    category,
    label: FROM_ACCOUNT_DROPDOWN,
  },
});

/**
 * The analytics event to trigger when the user clicks the alerts button.
 */
export const alertsButtonClicked = analyticsTrackEvent.bind(null, {
  eventName: 'Alerts Button Clicked',
  properties: { category },
});

/**
 * The analytics event to trigger when the user navigates to a module/link by
 * clicking the module/link card component on the dashboard.
 *
 * @param name - The module or link name the user is navigating to.
 */
export const moduleSelectedFromDashboard = (
  name: string
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: `${name} Selected`,
    properties: { category, label: FROM_DASHBOARD },
  });

/**
 * The analytics event to trigger when the bento box is opened.
 *
 * Note: this will eventually need to be updated to remove the `label` since it
 * will only be called from the "Dashboard" app
 */
export const bentoBoxViewed = analyticsTrackEvent.bind(null, {
  eventName: 'Bento Box Viewed',
  properties: { category, label: 'Dashboard' },
});

/**
 * The analytics event to trigger when the user navigates to a module/link by
 * clicking a link from within the bento box menu.
 *
 * @param name - The module or link name the user is navigating to.
 */
export const moduleSelectedFromBento = (
  name: string
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: `${name} Selected`,
    properties: { category, label: FROM_BENTO },
  });

/**
 * The analytics event to trigger when the user navigates to the Email and Feeds
 * dashboard by clicking on the Go to Email and Feeds button in the
 * Digital Signage Dashboard.
 *
 */
export const emailAndFeedsSelected = ({
  location = 'Dashboard',
  category = 'Navigation',
}: {
  location?: string;
  category?: string;
}): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'Go to Email and Feeds',
    properties: {
      category,
      label: `From ${location}`,
    },
  });
