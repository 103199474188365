import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsEvent, analyticsTrackEvent } from 'fwi-fe-utils';

const category = 'Alerts';

/**
 * The analytics event to trigger when the create alert modal becomes visible.
 */
export const createAlertModalDisplayed = analyticsTrackEvent.bind(null, {
  eventName: 'Create Alert Modal Displayed',
  properties: { category },
});

/**
 * The analytics event to trigger when the edit alert modal becomes visible.
 */
export const editAlertModalDisplayed = analyticsTrackEvent.bind(null, {
  eventName: 'Edit Alert Modal Displayed',
  properties: { category },
});

/**
 * The analytics event to trigger once an alert has been created.
 *
 * @param users The number of users the alert has been created for.
 */
export const alertCreated = (users: number): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'Alert created',
    properties: {
      category,
      label: `${users} Users`,
    },
  });

/**
 * The analytics event to trigger once an alert has been edited.
 *
 * @param users The number of users the alert has been edited to include.
 */
export const alertEdited = (users: number): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'Alert edited',
    properties: {
      category,
      label: `${users} Users`,
    },
  });

/**
 * The analytics event to trigger once an alert has been deleted.
 */
export const alertDeleted = analyticsTrackEvent.bind(null, {
  eventName: 'Alert deleted',
  properties: { category },
});

/**
 * The analytics event to trigger once a user clicks the undo button after
 * deleting an alert.
 */
export const undoAlertDeleted = analyticsTrackEvent.bind(null, {
  eventName: 'Delete Undo from Toast',
  properties: { category },
});
