import { LOCATION_CHANGE } from 'connected-react-router';
import { AnyAction, Middleware } from 'redux';

import { SYS_NAME_KEYS } from 'fwi-constants';
import { EntityId } from 'fwi-fe-types';
import {
  analytics,
  analyticsTrackEvent,
  authTokenController,
  getCompanyId,
  identifyCustomer,
  identifyUser,
  UserTraits,
} from 'fwi-fe-utils';

import {
  getCurrentCompany,
  getCurrentUser,
  isAuthenticated,
} from 'appState/auth/selectors';

export interface OptionalInfo {
  company?: {
    id: EntityId;
    name: string;
  };
}

function identify(
  { permissions, email, licenseType, firstName, lastName }: UserTraits,
  optionalInfo?: OptionalInfo
): void {
  const entityId = authTokenController.getDecodedAccessToken()?.entityId ?? '';
  const isAdmin = !!permissions?.find(
    (perm) =>
      perm.moduleId === SYS_NAME_KEYS.AdminCenter && perm.effect === 'allow'
  );

  const groupId = optionalInfo?.company
    ? optionalInfo.company.id
    : getCompanyId();

  analytics.identify(entityId, {
    groupId,
    isAdmin,
    isInternal: /(fwi|fourwindsinteractive|poppulo)\.com$/.test(
      email.toLowerCase()
    ),
    licenseType,
    email,
    firstName,
    lastName,
    ...optionalInfo,
  });
}

export const analyticsMiddleware: Middleware =
  (store) => (next) => (action: AnyAction) => {
    if (typeof action === 'function') {
      return next(action);
    }

    const state = store.getState();
    const user = getCurrentUser(state);
    const company = getCurrentCompany(state);
    const optionalInfo: OptionalInfo = {};
    if (company) {
      const { id, name } = company;

      optionalInfo.company = {
        id,
        name,
      };
    }

    const disableAnalyticsCheck =
      action.payload?.properties?.disableAnalyticsCheck;
    if (!user?.isTrackAnalyticsAllowed || disableAnalyticsCheck) {
      return next(action);
    }

    if (analyticsTrackEvent.match(action)) {
      analytics.track(action.payload.eventName, {
        ...action.payload.properties,
        groupId: company?.id || getCompanyId(),
      });
    } else if (identifyCustomer.match(action)) {
      analytics.group(company?.id || getCompanyId(), {
        salesforceAccountNumber: action.payload,
      });
    } else if (identifyUser.match(action)) {
      identify(action.payload, optionalInfo);
    } else if (action.type === LOCATION_CHANGE) {
      const { pathname } = action.payload.location;
      analytics.page(pathname, { path: pathname });
      if (isAuthenticated(state, false) && user.licenseType && user.email) {
        identify(user, optionalInfo);
      }
    }

    return next(action);
  };
