import { PayloadAction } from '@reduxjs/toolkit';
import { analyticsTrackEvent, AnalyticsEvent } from 'fwi-fe-utils';
import { ModulePermission } from 'fwi-fe-types';

import {
  AppDispatch,
  ModuleAccess,
  RestrictableModuleAccess,
  RESTRICTABLE_MODULE_ACCESS_NAME,
} from 'appTypes';
import {
  buildCompletePermissions,
  isPermissionEqual,
  isPermissionLimited,
} from 'utils/permissions';

const category = 'Groups';
const FROM_GROUP_PAGE = 'From Group Page';
const FROM_PROPERTIES_PANEL = 'From Properties Panel';

/**
 * The analytics event to trigger whenever a group is created.
 */
export const groupCreated = analyticsTrackEvent.bind(null, {
  eventName: 'Group Created',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a group is modified.
 */
export const groupModified = analyticsTrackEvent.bind(null, {
  eventName: 'Group Modified',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a group is duplicated.
 *
 * @param modal Boolean if the event was triggered from the group's super modal.
 */
export const groupDuplicated = (
  modal: boolean
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'Group Duplicated',
    properties: {
      category,
      label: modal ? FROM_GROUP_PAGE : FROM_PROPERTIES_PANEL,
    },
  });

/**
 * The analytics event to trigger whenever a group is deleted.
 *
 * @param modal Boolean if the event was triggered from the group's super modal.
 */
export const groupDeleted = (modal: boolean): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'Group Deleted',
    properties: {
      category,
      label: modal ? FROM_GROUP_PAGE : FROM_PROPERTIES_PANEL,
    },
  });

/**
 * The analytics event to trigger whenever a group's access to different modules
 * is changed.
 *
 * @param prev The previous access level
 * @param next The next access level
 * @param module The module that has the restrictions changed.
 * @param restrictions The number of restrictions enforced by setting
 * specific folder ids. This _should_ be `0` if there are no restrictions.
 */
export const groupAccessLevelChanged = (
  prev: ModuleAccess,
  next: ModuleAccess,
  module: RestrictableModuleAccess,
  restrictions: number
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: `Group ${RESTRICTABLE_MODULE_ACCESS_NAME[module]} Permissions Updated`,
    properties: {
      category,
      label: `Access level changed from ${prev} to ${next}`,
      value: restrictions,
    },
  });

/**
 * This is a thunked action that will compare the permissions objects to see if
 * access has changed for any of our modules.
 *
 * @param prev - The previous group permissions
 * @param next - The next group permissions
 */
export const groupPermissionsChanged =
  (prev: readonly ModulePermission[], next: readonly ModulePermission[]) =>
  (dispatch: AppDispatch): void => {
    const prevPermissions = buildCompletePermissions(prev);
    const nextPermissions = buildCompletePermissions(next);
    Object.entries(RESTRICTABLE_MODULE_ACCESS_NAME).forEach(
      ([moduleKey, moduleId]) => {
        const module = moduleKey as RestrictableModuleAccess;

        const prev = prevPermissions.find((perm) => perm.moduleId === moduleId);
        const next = nextPermissions.find((perm) => perm.moduleId === moduleId);
        if (!isPermissionEqual(prev, next) && prev && next) {
          const beforeEffect = isPermissionLimited(prev)
            ? ModuleAccess.Limited
            : prev.effect;
          const afterEffect = isPermissionLimited(next)
            ? ModuleAccess.Limited
            : next.effect;
          const restrictions = next.resources.filter(
            (resource) => resource !== ModuleAccess.Everything
          ).length;

          dispatch(
            groupAccessLevelChanged(
              beforeEffect,
              afterEffect,
              module,
              restrictions
            )
          );
        }
      }
    );
  };
