import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  getCurrentMediaTypes,
  fixAndroidKeyboards,
  isInputActive,
} from 'fwi-fe-utils';
import { useIntl } from 'fwi-fe-components';

import {
  getCurrentCompany,
  getErrorCode,
  getReportingStage,
  isAuthenticated,
  isMobile,
  updateAppSize,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { useSplitEnabled } from 'hooks/useSplit';

import {
  isAdmin,
  isAlerts,
  isCustomers,
  isDevices,
  isEula,
  isLibrary,
  isLogout,
  isReporting,
  isUnifiedDashboard,
} from 'utils/routes';
import { AppName, getAppName } from './utils';
import { SetHtmlClassName } from './HtmlClassNameContext';
import { AppDispatch, ReportingModuleStage } from 'appTypes';

import styles from './useAppLayout.module.scss';

export function getChangePercent(previous: number, current: number): number {
  return (Math.abs(previous - current) / current) * 100;
}

const WHITE_BG_APPS: readonly AppName[] = ['Dashboard'];

export interface AppLayout {
  /**
   * Boolean if the user is **fully** authenticated.
   */
  authenticated: boolean;

  /**
   * The current page title that should be displayed in the header
   */
  title: string;

  /**
   * Boolean if the app is current in mobile mode.
   */
  mobile: boolean;

  /**
   * Boolean if the side panel should be visible. This is mostly a desktop-only
   * flag.
   */
  drawer: boolean;

  /**
   * Boolean if the app is in an "errored" state because of a status code or
   * something else.
   */
  errored: boolean;

  /**
   * Boolean if the main layout should have stacked toolbars. This is mostly a
   * desktop-only flag.
   */
  stacked: boolean;

  /**
   * Boolean if the drawer should be visible. This is mostly for mobile with the
   * temporary navigation dialog.
   */
  visible: boolean;

  /**
   * Boolean if the `<main>` element should add the `.eula-grid` class.
   */
  eulaGrid: boolean;

  /**
   * Boolean if the `<main>` element should be rendered as a `grid` instead of
   * `block`. This is useful if you want to force the content to be full page
   * height.
   */
  contentGrid: boolean;

  /**
   * Boolean if FWI Cloud is in maintenance mode for a release.
   */
  maintenance: boolean;

  unifiedDashboard: boolean;

  /**
   * A function to pass to the `NavigationDrawer` to handle the visibility
   * changes when the hamburger menu is clicked on mobile.
   */
  onVisibilityChange(visible: boolean): void;

  /**
   * @see {@link SetHtmlClassName}
   */
  setHtmlClassName: SetHtmlClassName;
}

/**
 * Checks if a route has a navigation drawer on desktop.
 *
 * @returns true if the drawer should exist
 */
const isDrawerRoute = (
  pathname: string,
  reportingModuleStage: ReportingModuleStage
): boolean =>
  isAdmin(pathname) ||
  isDevices(pathname) ||
  isLibrary(pathname) ||
  (isReporting(pathname) &&
    reportingModuleStage === ReportingModuleStage.Unavailable) ||
  isCustomers(pathname);

/**
 * This is basically what we had in
 * `fwi-fe-components/src/components/Layout/state/drawer.js`
 * and I have no idea why it's so complex. Probably issues we
 * ran into with tables and android keyboards...
 */
const onWindowResize =
  (
    setVisible: (value: React.SetStateAction<boolean>) => void,
    dispatch: AppDispatch
  ) =>
  (): void => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    fixAndroidKeyboards();
    const inputActive = isInputActive();
    const { innerHeight, innerWidth } = window;
    const hChange = getChangePercent(height, innerHeight);
    const wChange = getChangePercent(width, innerWidth);

    if (
      inputActive &&
      ((hChange && hChange > 35) || (wChange && wChange < 1))
    ) {
      return;
    }

    const { mobile, desktop } = getCurrentMediaTypes();
    setVisible(desktop);
    dispatch(updateAppSize({ mobile, desktop }));
  };

export const getClassName = ({
  isUnifiedDashboardRoute,
  htmlClassName,
  authenticated,
  appName,
}: {
  isUnifiedDashboardRoute: boolean;
  htmlClassName: string;
  authenticated: boolean;
  appName: AppName;
}): { className: string; html: HTMLElement } | void => {
  const html = document.querySelector('html');
  if (!html) {
    return;
  }

  let className = htmlClassName;
  if (isUnifiedDashboardRoute) {
    className = styles.dashboard;
  } else if (!className) {
    className =
      !authenticated || WHITE_BG_APPS.includes(appName)
        ? styles.white
        : styles.pearl;
  }

  return { className, html };
};

const setDocumentTitle = ({
  title,
  appName,
  authenticated,
  intl,
}: {
  title: string;
  appName: AppName;
  authenticated: boolean;
  intl: ReactIntl.InjectedIntl;
}): void => {
  let documentTitle = title;

  if (appName === 'Dashboard' || appName === 'LoggedOut') {
    documentTitle = intl.formatMessage({
      id: `SEO.Logged${authenticated ? 'In' : 'Out'}Title`,
    });
  }

  document.title = documentTitle;
};

export function useAppLayout(): AppLayout {
  const intl = useIntl();
  const { pathname } = useLocation();
  const prevPathname = useRef(pathname);
  const mobile = useAppSelector((state) => isMobile(state));
  const isEulaRoute = isEula(pathname);
  const isUnifiedDashboardRoute = isUnifiedDashboard(pathname);
  const isFullyAuthenticated = useAppSelector((state) =>
    isAuthenticated(state, true)
  );
  const currentCompany = useAppSelector(getCurrentCompany);
  const authenticated =
    isFullyAuthenticated &&
    !isEulaRoute &&
    !isLogout(pathname) &&
    !!currentCompany;
  const maintenance = useSplitEnabled('DS_MAINTENANCE');

  const reportingModuleStage = useAppSelector(getReportingStage);

  const errored =
    useAppSelector((state) => !!getErrorCode(state)) || maintenance;
  const dispatch = useAppDispatch();
  const stacked = !errored && authenticated && !mobile;
  const drawer =
    !errored &&
    authenticated &&
    (mobile || isDrawerRoute(pathname, reportingModuleStage));
  const contentGrid = isEulaRoute || (isAlerts(pathname) && !errored);
  const [htmlClassName, setHtmlClassName] = useState('');
  const [visible, setVisible] = useState(!mobile);

  const appName: AppName = getAppName(authenticated, pathname);
  const title = intl.formatMessage({ id: `AppTitle.${appName}` });

  useEffect(() => {
    setDocumentTitle({ title, appName, authenticated, intl });
  }, [appName, authenticated, intl, title]);

  if (prevPathname.current !== pathname) {
    prevPathname.current = pathname;
    if (mobile) {
      setVisible(false);
    }
  }

  useEffect(() => {
    const handleOnWindowResize = onWindowResize(setVisible, dispatch);

    window.addEventListener('resize', handleOnWindowResize);
    return () => {
      window.removeEventListener('resize', handleOnWindowResize);
    };
  }, [dispatch]);

  useEffect(() => {
    const result = getClassName({
      isUnifiedDashboardRoute,
      htmlClassName,
      authenticated,
      appName,
    });

    if (!result) {
      return;
    }

    const { className, html } = result;

    html.classList.add(className);
    return () => {
      html.classList.remove(className);
    };
  }, [appName, authenticated, htmlClassName, isUnifiedDashboardRoute]);

  return {
    authenticated,
    title,
    mobile,
    drawer,
    errored,
    stacked,
    visible,
    eulaGrid: isEulaRoute,
    contentGrid,
    maintenance,
    unifiedDashboard: isUnifiedDashboardRoute,
    onVisibilityChange: (visible) => setVisible(visible),
    setHtmlClassName,
  };
}
