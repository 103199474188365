import { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsEvent, analyticsTrackEvent } from 'fwi-fe-utils';

const DASHBOARD = 'Dashboard';
const FROM_DS_DASHBOARD = 'From DS Dashboard';

export const navigateToEmailAndFeedsFromDashboard = (
  moduleName: string
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: `Go to EF ${moduleName}`,
    properties: {
      category: DASHBOARD,
      label: FROM_DS_DASHBOARD,
    },
  });

export const changeEmailAndFeedsSubAccount = analyticsTrackEvent.bind(null, {
  eventName: 'EF Sub Account Changed',
  properties: {
    category: DASHBOARD,
    label: FROM_DS_DASHBOARD,
  },
});
