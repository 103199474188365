import {
  EntityId,
  EntityMetadata,
  LabelInputType,
  LabelValueEntity,
  SortOrder,
} from 'fwi-fe-types';

import { baseApi } from 'appState/baseApi';
import { LabelSortKey, LabelsSearchOptions } from 'appTypes';
import { LABELS_SEARCH_ENDPOINT } from 'constants/endpoints';
import { format } from 'utils/api';

import { normalizeLabels } from './schema';
import { mergeLabels } from './reducer';

export interface OptimizedLabelEntity extends EntityMetadata {
  /**
   * The unique id for the label.
   */
  id: EntityId;

  /**
   * The name for the label.
   */
  name: string;

  /**
   * The type of the label.
   */
  inputType: LabelInputType;

  /**
   * Boolean if this is a default label that was generated automatically.
   */
  isDefault: boolean;

  /**
   * A list of values associated to this label.
   */
  values: LabelValueEntity[];

  /**
   * The description for the label.
   */
  description: string;

  /**
   * Boolean if this is an internal system label.
   */
  isSystemLabel: boolean;

  deviceCount: number;
}

export interface LabelsSearchResponse {
  offset: number;
  size: number;
  sortBy: LabelSortKey;
  sortOrder: SortOrder;
  total: number;
  search: string;
  items: OptimizedLabelEntity[];
}

export const labelsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    labelsSearch: builder.query<
      LabelsSearchResponse,
      Partial<LabelsSearchOptions> | void
    >({
      providesTags: ['Labels'],
      query: ({
        size = 100,
        offset = 0,
        sort = 'name',
        sortOrder = SortOrder.ASCENDING,
        search,
      } = {}) => ({
        url: format(LABELS_SEARCH_ENDPOINT),
        method: 'POST',
        body: {
          size,
          offset,
          sortBy: sort,
          sortOrder,
          search,
          docType: 'label',
          filter: {
            isDefault: false,
          },
        },
      }),

      // This is used to merge the rtk-query cache with the "legacy"
      // `appState/labels/reducer.ts` so that the data can be used in the
      // properties panel without a big rewrite. should be removed in the
      // future
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const labels = normalizeLabels(data.items);
          dispatch(mergeLabels(labels));
        } catch {
          // silently fail
        }
      },

      // To make this work, have a single cache based on the endpointName
      // instead of endpoint + serialized args
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      // When requesting the next page, merge the items when requesting the next page
      merge: (currentCache, nextResponse) => {
        if (nextResponse.offset > 0) {
          nextResponse.items = [...currentCache.items, ...nextResponse.items];
        }
        return nextResponse;
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useLabelsSearchQuery } = labelsApi;
